.my-navbar {
  margin-top:10px;
}
.my-navbar .nav-item {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #FDD017;
  border-radius: 8px;
  margin: 2px 0px;
}
.selected {
  font-weight: bold;
  background-color: #FDD017;
}

.my-navbar .nav-item:hover {
  background-color: #ffff80;
}
.icon {
  margin-right: 12px;
}