.modal-header-custom {
  font-size: 13px !important;
  margin: 5px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.alert-padding {
  padding: 5px 10px 5px 10px !important;
}
.padding-top-bottom4{
  padding-top:4px !important;
  padding-bottom: 4px !important;
}
.padding-0 {
  padding: 0px !important;
}
.padding-4{
  padding:4px !important;
}
.padding-10 {
  padding:10px !important;
}
.padding-left0{
  padding-left: 0px !important;
}
.padding-bottom5{
  padding-bottom: 5px !important;
}
.padding-left21{
  padding-left: 21px !important;
}
.margin-0{
  margin:0px !important;
}
.margin-15{
  margin:15px !important;
}
.mar-top-bottom-10 {
  margin: 10px 0px !important;
}
.mar-right10 {
  margin-right: 10px !important;
}
.mar-right15 {
  margin-right: 15px !important;
}
.mar-right17 {
  margin-right: 17px !important;
}
.mar-top5 {
  margin-top:5px !important;
}
.mar-top7 {
  margin-top:7px !important;
}
.mar-top10 {
  margin-top:10px !important;
}
.mar-top3{
  margin-top:3px !important;
}
.mar-bottom5 {
  margin-bottom:5px !important;
}
.mar-bottom20 {
  margin-bottom:20px !important;
}
.mar-top20 {
  margin-top:20px !important;
}
.mar-top25 {
  margin-top:25px !important;
}
.mar-topM25 {
  margin-top:-25px !important;
}
.mar-leftm5{
  margin-left:-5px !important;
}
.mar-right5{
  margin-right:5px !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.font-weight-normal {
  font-weight: normal !important;
}

.btn {
  text-transform: uppercase;
}
.red {
  color: #f00;
}
.notification{
  font-weight: bold !important;
  font-size:12px !important;
  text-transform: capitalize;
}
a.btn, button.btn-link,button.btn {font-size: 10px !important; font-weight: bold; color: #000 !important; }
a.btn:hover, button.btn-link:hover { text-decoration: underline }
.btn:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.business-radio {
  margin:5px 0px 5px 20px !important;
}
.business-radio-text {
  margin-top: 3px !important;
}
.business-country-code {
  padding: 10px 20px !important;
}
.btn-warning {
  background-color: #FDD017 !important;
  border-color: #FDD017 !important;
}
.display-block-center {
  display:block;
  text-align: center;
}
.heading-header {
  color:  #FDD017;
  width: 300px;
  margin: 10px auto 10px auto;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold;
  text-transform: uppercase;
}
.logged-in-user {
  color:  #FDD017;
  font-size: 14px !important;
  font-weight: bold;
  margin: 10px 5px 10px 0px;
}

.main-heading {
  color:  #000;
  /* width: 400px; */
  margin: 10px auto;
  text-align: center;
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: bold;
}
.maintenance-menu-box {
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75) !important;
}
.card-header-custom {
  font-size: 13px !important;
  padding: 6px 10px !important;
  text-transform: uppercase;
}
.card-body-custom {
  padding: 10px !important;
  font-size: 12px !important;
}

.grid-edit-btn {
  padding: 2px 10px 21px 10px !important;
  margin-top: -5px !important;
  height: 18px !important;
}
.pagination-dropdown {
  padding: 5px 0px;
}
.pagination-dropdown select {
  display: inline;
  margin-left: 5px;
  width: 80px;
}
.dashboard{
  width:100%;
  height: auto;
}

.maintenance-menu-card {
  width: 970px;
  margin: 40px auto;
}
.maintenance-menu-card .col-lg, .maintenance-menu-card .col-lg-3 {
  text-align: center;
}
/* Settelement Reason Code */
.file-alert {
  margin: 15px auto;
  width: 600px;
}
/* Settelement Reason Code */

/* Money Transfer Utility */
.mtcn-btn {
  /* width:83px !important; */
  width:88px !important;
  margin-right:2px;
}
.mtcn-madrec-data {
  height: 117px;
  padding: 0px !important;
  overflow-y: auto;
}
.mtcn-mtub-data {
  height: 230px;
  padding: 0px !important;
  overflow-y: auto;
}
.mtcn-record-display span{
  display: block;
  margin-left: 15px;
  margin-top: 10px;
}
.search-key-error {
  width:auto;
  display:inline-block;
}
.mtcn-list-container{
  width:650px;
  margin: 0 auto;
}
.mtcn-list-button-container{
  width:650px;
  margin: 0px auto;
}
/* Money Transfer Utility */

/* Country Maintenance */
.country-container{
  width:750px;
  margin: 0px auto;
}
/* Country Maintenance */
/* Header css */
.nav-logo {
  justify-content: flex-start;
  cursor:pointer;
}
.nav-env-name {
  position: absolute;
  width:auto;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nav-login-details {
  justify-content: flex-end;
  cursor:pointer;
}
/* Header css */

/* TMON Error Queue */
.eqms-container {
  width:850px;
  margin: 0px auto;
}
/* TMON Error Queue */

/* Quick Collect Update */
.rpps-biller-radio {
  margin-left:14px !important;
  margin-top:22px !important;
}
.rpps-biller-label {
  margin-top:29px !important;
}
.rate-type-label {
  margin-top: 32px !important;
  margin-left:10px;
}
.remove-checkbox {
  margin-top:20px !important;
}
.remove-checkbox-label {
  margin-top:21px !important;
}
/* Quick Collect Update */

/* NRT Agent Network Screen */
.input-checkbox-style {
  height: 25px;
  width: 15%;
}
/* NRT Agent Network Screen */

/* Tip Log */
.tip-log-search-container {
  width:750px;
  margin: 0px auto;
}
.account-log-search-container {
  width:600px;
  margin: 0px auto;
}
.padding-7{
  padding:7px !important;
}
.tlarTable tr th{ 
  width:200px !important;
}
/* Tip Log */