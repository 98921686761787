/* * {
  font-family: Arial,sans-serif !important;
} */
body {
  margin: 0;
  /* font-family: Arial,sans-serif !important; */
  font-family: Open Sans, sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", Open Sans, sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px !important;
}
.btn, h1, h2, h3, h4, h5, p, .alert,.card-header {
  font-size: 11px !important;
}
input, select, label {
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}
